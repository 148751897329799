<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <div class="w-100 i-margin-div-w" />
        <div class="i-progress-container mt-1 mb-3">
          <div
            id="i-progress"
            class="i-progress"
          />
          <!-- Integrante -->
          <div class="i-circle-progress active">
            <span style="transform: translate(0, -30px);">
              <p
                class="text-center mt-1 d-none d-sm-none d-md-block"
                style="white-space:nowrap;"
              >
                <feather-icon
                  size="24"
                  icon="UserIcon"
                  class="mr-50"
                />
                <span class="align-bottom">{{ $t('member.wizard.title.one') }}</span>
              </p>

              <!-- Version Mobile -->
              <div class="text-center mt-3 pt-2 d-block d-sm-block d-md-none">
                <p>
                  <feather-icon
                    size="24"
                    icon="UserIcon"
                  />
                </p><br>
                <p v-if="currentActive === 1">
                  <span>{{ $t('member.wizard.title.one') }}</span>
                </p>
                <p
                  v-else
                  style="margin-top: 33px;"
                />
              </div>

              <!-- End version mobile -->
            </span>
          </div>
          <!-- Info. general -->
          <div class="i-circle-progress">
            <span style="transform: translate(0, -30px);">
              <p
                class="text-center mt-1 d-none d-sm-none d-md-block"
                style="white-space:nowrap;"
              >
                <i
                  class="icon-0-icons-dark-newsfeed text-center mr-50"
                  style="font-size: 18px;"
                />
                <span class="align-bottom">{{ $t('member.wizard.title.two') }}</span>
              </p>

              <!-- Version Mobile -->
              <div class="text-center mt-3 pt-2 d-block d-sm-block d-md-none">
                <p>
                  <i
                    class="icon-0-icons-dark-newsfeed text-center"
                    style="font-size: 18px;"
                  />
                </p><br>
                <p v-if="currentActive === 2">
                  <span>{{ $t('generic.general') }}</span>
                </p>
                <p
                  v-else
                  style="margin-top: 33px;"
                />
              </div>

              <!-- End version mobile -->
            </span>
          </div>

          <!-- Condiciones -->
          <div class="i-circle-progress">
            <span style="transform: translate(0, -30px);">
              <p
                class="text-center mt-1 d-none d-sm-none d-md-block"
                style="white-space:nowrap;"
              >
                <i
                  class="icon-0-icons-dark-lock-closed mr-50"
                  style="font-size: 18px;"
                />
                <span class="align-bottom"> {{ $t('member.wizard.title-three') }} </span>
              </p>

              <!-- Version Mobile -->
              <div class="text-center mt-3 pt-2 d-block d-sm-block d-md-none">
                <p>
                  <i
                    class="icon-0-icons-dark-lock-closed text-center"
                    style="font-size: 18px;"
                  />
                </p><br>
                <p v-if="currentActive === 3">
                  <span>{{ $t('member.wizard.title-three') }}</span>
                </p>
                <p
                  v-else
                  style="margin-top: 33px;"
                />
              </div>
              <!-- End version mobile -->

            </span>
          </div>

          <!-- Documentación -->
          <div class="i-circle-progress">
            <span style="transform: translate(0, -30px);">
              <p
                class="text-center mt-1 d-none d-sm-none d-md-block"
                style="white-space:nowrap;"
              >
                <i
                  class="icon-fill-200 mr-50"
                  style="font-size: 18px;"
                />
                <span class="align-bottom">{{ $t('member.wizard.title.three') }}</span>
              </p>

              <!-- Version Mobile -->
              <div class="text-center mt-3 pt-2 d-block d-sm-block d-md-none">
                <p>
                  <i
                    class="icon-fill-200 text-center"
                    style="font-size: 18px;"
                  />
                </p><br>
                <p v-if="currentActive === 4">
                  <span>{{ $t('member.wizard.title.three') }}</span>
                </p>
                <p
                  v-else
                  style="margin-top: 33px;"
                />
              </div>
              <!-- End version mobile -->

            </span>
          </div>
        </div>
      </b-col>
    </b-row>
    <head-title-wizard
      :section-player="sectionPlayer"
      :section-coaching-staff="sectionCoachingStaff"
      :option="option"
      :gender="genderSelect ? genderSelect : '' "
    />
    <!-- Avatar -->
    <b-row>
      <b-col
        sm="12"
        md="12"
        lg="12"
      >
        <b-row align-h="center">
          <b-col>
            <div class="text-center">
              <upload-image
                :tooltip-text="'generic.allowedFormat'"
                :button-text="'generic.uploadPhoto'"
                :avatar-icon="'icon-0-icons-dark-avatar'"
                :image-size="'10rem'"
                :apply-b-link="true"
                :disabled="member.club_hash == null"
                :path-file-avatar="member.avatar_file.url_thumb"
                @intance-file-x="fileValidation($event, 'avatar_file')"
              />
              <br>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- End Avatar -->
    <b-row>
      <b-col
        v-if="currentActive == 1"
        cols="12"
      >
        <validation-observer
          ref="formMemberGeneral"
        >
          <b-form>
            <b-row>
              <!-- Formulario general -->
              <template v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])">
                <b-col sm="12">
                  <span>{{ $t('generic.club') }}</span>
                  <hr>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                  lg="6"
                >
                  <b-form-group>
                    <label for="club-country-id">{{ $t('generic.country') }} <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="country"
                    >
                      <!-- v-model="idCountry" -->
                      <v-select
                        id="club-country-id"
                        v-model="member.club.country_hash"
                        label="name"
                        :options="countries"
                        :reduce="country => country.id"
                        :loading="isLoadingCatalog"
                        :placeholder="$t('generic.selectCountry')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <div slot="no-options">
                          {{ $t('generic.noRecordsFound') }}
                        </div>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                  lg="6"
                >
                  <b-form-group>
                    <label for="club-state-id">{{ $t('generic.state') }} <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="city"
                    >
                      <!-- v-model="idState" -->
                      <v-select
                        id="club-state-id"
                        v-model="member.club.state_hash"
                        label="name"
                        :options="clubStates"
                        :reduce="city => city.hash"
                        :disabled="member.club.country_hash == null"
                        :loading="isLoadingCatalog"
                        :placeholder="$t('generic.selectState')"
                      >
                        <div slot="no-options">
                          {{ $t('generic.noRecordsFound') }}
                        </div>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>
              <b-col
                v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group>
                  <label for="club-id">{{ $t('generic.club') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="sportsClubName"
                  >
                    <v-select
                      id="club-id"
                      v-model="member.club_hash"
                      label="club_name"
                      :options="clubs"
                      :reduce="club => club.hash"
                      :loading="isLoadingCatalog"
                      :disabled="member.club.state_hash == null"
                      :placeholder="$t('corporate.selectSportClub')"
                    >
                      <!-- :disabled="corporateList.length === 1" -->
                      <div slot="no-options">
                        {{ $t('generic.noRecordsFound') }}
                      </div>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- team -->
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group>
                  <label>{{ $t('generic.team') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="team"
                  >
                    <v-select
                      v-model="member.team_hash"
                      label="name"
                      :placeholder="$t('generic.selectTeam')"
                      :options="teams.data"
                      :reduce="item => item.hash"
                      :loading="isLoadingCatalog"
                      :disabled="member.club_hash == null"
                    >
                      <!-- :selectable="(item) => item.status" -->
                      <div slot="no-options">
                        {{ $t('generic.noRecordsFound') }}
                      </div>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- end Team -->
              <!-- Category -->
              <template v-if="option == sectionPlayer">
                <b-col
                  sm="12"
                  md="6"
                  lg="6"
                >
                  <b-form-group>
                    <label>{{ $t('member.form.label.category') }} <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="category"
                    >
                      <v-select
                        v-model="member.category_hash"
                        label="name"
                        :placeholder="$t('member.form.placeholder.selectCategory')"
                        :options="categories"
                        :reduce="item => item.id"
                      >
                        <div slot="no-options">
                          {{ $t('generic.noRecordsFound') }}
                        </div>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
                  sm="12"
                  md="6"
                  lg="6"
                />
              </template>
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b><span>{{ $t('generic.contractTerm') }}</span></b>
              <!-- <hr> -->
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
              />
              <b-col
                sm="12"
                md="3"
                lg="3"
              >
                <b-form-group>
                  <label>{{ $t('generic.initDate') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="initDate"
                  >
                    <b-form-datepicker
                      v-model="member.validity_init_date"
                      :locale="$i18n.locale"
                      class="form-control"
                      :placeholder="$t('member.form.placeholder.selectDate')"
                      :state="errors.length > 0 ? false : null"
                    />
                    <!--:date-info-fn="() => saveDataTemporarily_temp()"-->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="3"
                lg="3"
              >
                <!-- <div v-if="member.contractIsIndefinite == 0"> -->
                <b-form-group>
                  <label>{{ $t('generic.endDate') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="endDate"
                  >
                    <!-- :date-disabled-fn="configDateValidityEnd" -->
                    <b-form-datepicker
                      v-model="member.validity_end_date"
                      :locale="$i18n.locale"
                      :min="member.validity_init_date"
                      class="form-control"
                      :placeholder="$t('member.form.placeholder.selectDate')"
                      :state="errors.length > 0 ? false : null"
                      :disabled="member.validity_init_date == null"
                    />
                    <!--:date-info-fn="() => saveDataTemporarily_temp()"-->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              <!-- </div> -->
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <upload-file
                  :title="$t('member.form.label.contract')"
                  folder="members"
                  :disabled="member.club_hash == null"
                  :club-id="member.club_hash"
                  :file-title="member.file_contract ? member.file_contract.title : ''"
                  @uploaded-file="assignFileContract"
                />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group>
                  <label>{{ $t('member.form.label.registrationFederation') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="registrationFederation"
                    rules="required|max:254"
                  >
                    <b-form-input
                      v-model="member.registration_in_federation"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('member.form.placeholder.registryNumber')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <template v-if="option == sectionPlayer">
                <b-col
                  sm="12"
                  md="6"
                  lg="6"
                >
                  <b-form-group>
                    <label>{{ $t('member.form.label.idFifa') }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="idFifa"
                      rules="integer|max:254"
                    >
                      <b-form-input
                        v-model="member.id_fifa"
                        type="text"
                        :placeholder="$t('member.form.placeholder.enterIdFifa')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group>
                  <label>{{ $t('member.form.label.dateAdmission') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="dateAdmission"
                    rules="required"
                  >
                    <!-- <flat-pickr
                      v-model="member.date_of_admission"
                      :state="errors.length > 0 ? false : null"
                      class="form-control"
                      :placeholder="$t('member.form.placeholder.selectDate')"
                      :config="configDateAdmission"
                    /> -->
                    <b-form-datepicker
                      v-model="member.date_of_admission"
                      :locale="$i18n.locale"
                      :min="member.validity_init_date"
                      :max="member.validity_end_date"
                      class="form-control"
                      :placeholder="$t('member.form.placeholder.selectDate')"
                      :state="errors.length > 0 ? false : null"
                    />
                    <!--:date-info-fn="() => saveDataTemporarily_temp()"-->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Solo para jugador -->
              <template v-if="option == sectionPlayer">
                <b-col
                  sm="6"
                  md="3"
                  lg="3"
                >
                  <!-- :jersey-number="member.number" -->
                  <jersey-number-modal
                    :jersey-numbers="jerseyNumbers"
                    :jersey-number.sync="member.jersey_number"
                    :color-primary-btn="colorPrimaryBtn"
                    @handle-jersey-by-team-id="handleJerseyByTeamId"
                  />
                <!-- @select-number="member.number = $event" -->
                </b-col>
                <div class="w-100" />
                <b-col
                  sm="6"
                  md="3"
                  lg="3"
                >
                  <b-form-group>
                    <label>{{ $t('member.form.label.status') }} <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="status"
                    >
                      <v-select
                        v-model="member.player_status"
                        label="name"
                        :placeholder="$t('member.form.placeholder.selectStatus')"
                        :options="playerStatuses"
                        :state="errors.length > 0 ? false : null"
                      >
                        <!--@input="() => saveDataTemporarily_temp()"-->
                        <!-- :reduce="item => item.id" -->
                        <div slot="no-options">
                          {{ $t('generic.noRecordsFound') }}
                        </div>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="memberPlayerStatusKey == 'LOANED_BY'"
                  cols="12"
                  sm="12"
                  md="3"
                  lg="3"
                >
                  <b-form-group>
                    <label>{{ $t('club-cedente') }} <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="sportsClubName"
                      rules="required|max:254|alphaNumSpacesRegex"
                    >
                      <b-form-input
                        v-model="member.contractual_condition.transfer.ceding_club"
                        type="text"
                        :placeholder="$t('enter-sport-club-name')"
                      />
                      <!--@change="() => saveDataTemporarily_temp()"-->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="memberPlayerStatusKey == 'LOANED_TO'"
                  cols="12"
                  sm="12"
                  md="3"
                  lg="3"
                >
                  <b-form-group>
                    <label>{{ $t('transferee-club') }} <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="sportsClubName"
                      rules="required|max:254|alphaNumSpacesRegex"
                    >
                      <b-form-input
                        v-model="member.contractual_condition.transfer.ceding_club"
                        type="text"
                        :placeholder="$t('enter-sport-club-name')"
                      />
                      <!--@change="() => saveDataTemporarily_temp()"-->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>
              <!-- End status y jersey number -->
              <!-- Cuerpo técnico -->
              <template v-if="option == sectionCoachingStaff">
                <b-col
                  sm="12"
                  md="6"
                  lg="6"
                >
                  <b-form-group>
                    <label>{{ $t('member.form.label.gender') }} <span
                      class="text-danger"
                    >*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="gender"
                    >
                      <v-select
                        v-model="member.gender_hash"
                        label="name"
                        :placeholder="$t('member.form.placeholder.selectGender')"
                        :options="genders"
                        :reduce="item => item.id"
                        :state="errors.length > 0 ? false : null"
                        :loading="isLoadingCatalog"
                      >
                        <!-- :disabled="member.gender_hash != null " -->
                        <div slot="no-options">
                          {{ $t('generic.noRecordsFound') }}
                        </div>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="!applyOther"
                  sm="10"
                  md="4"
                  lg="4"
                >
                  <b-form-group>
                    <label for="name">
                      {{ $t("generic.jobPosition") }} <span
                        v-if="!applyOther"
                        class="text-danger"
                      >*</span>
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      :rules="applyOther ? '' : 'required'"
                      name="jobPosition"
                    >
                      <v-select
                        v-model="member.staff_job_position_hash"
                        label="name"
                        :options="staffJobPositions"
                        :reduce="job => job.id"
                        :disabled="applyOther || member.gender_hash == null"
                        :placeholder="$t('generic.jobPosition')"
                      >
                        <!--@input="() => saveDataTemporarily_temp()"-->
                        <div slot="no-options">
                          {{ $t('generic.noRecordsFound') }}
                        </div>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="applyOther"
                  sm="10"
                  md="4"
                  lg="4"
                >
                  <b-form-group>
                    <label for="name">
                      {{ $t("generic.jobPosition") }} <span class="text-danger">*</span>
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required|max:254|alphaNumSpacesRegex"
                      name="jobPosition"
                    >
                      <b-form-input
                        v-model="member.another_job"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('generic.enterJob')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="2"
                  md="2"
                  lg="2"
                >
                  <b-button
                    class="mt-2"
                    :variant="applyOther ? 'secondary' : 'primary'"
                    pill
                    @click="applyOther = !applyOther"
                  >
                    <span v-if="applyOther"> {{ $t('buttons.cancel') }} </span>
                    <span v-else>{{ $t('generic.addJobPosition') }}</span>
                  </b-button>
                </b-col>
              </template>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <b-col
        v-if="currentActive == 2"
        cols="12"
      >
        <validation-observer
          ref="formMemberPersonal"
        >
          <b-form>
            <b-row>
              <!-- Formulario Informacion personal -->
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group>
                  <label>{{ $t('member.form.label.name') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required|max:254|alphaNumSpacesRegex"
                    name="name"
                  >
                    <b-form-input
                      v-model="member.name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('member.form.placeholder.enterName')"
                    />
                    <!--@change="() => saveDataTemporarily_temp()"-->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group>
                  <label>{{ $t('member.form.label.firstName') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required|max:254|alphaNumSpacesRegex"
                    name="firstName"
                  >
                    <b-form-input
                      v-model="member.first_name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('member.form.placeholder.enterFirstName')"
                    />
                    <!--@change="() => saveDataTemporarily_temp()"-->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group>
                  <label>{{ $t('member.form.label.lastName') }}</label>
                  <b-form-input
                    v-model="member.last_name"
                    type="text"
                    :placeholder="$t('member.form.placeholder.enterLastName')"
                  />
                </b-form-group>
              </b-col>
              <!-- Fecha de nacimiento -->
              <b-col
                sm="12"
                md="3"
                lg="3"
              >
                <b-form-group>
                  <label>{{ $t('member.form.label.birthday') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="birthday"
                  >
                    <b-form-datepicker
                      v-model="member.birthday"
                      :locale="$i18n.locale"
                      :max="new Date()"
                      class="form-control"
                      :placeholder="$t('member.form.placeholder.selectDate')"
                      :state="errors.length > 0 ? false : null"
                    />
                    <!--:date-info-fn="() => saveDataTemporarily_temp()"-->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="3"
                lg="3"
              >
                <b-form-group>
                  <label>{{ $t('generic.nameLegalResponsible') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    :rules="validAge >= 18 ? '' : 'required'"
                    name="name"
                  >
                    <b-form-input
                      v-model="member.holder_name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('member.form.placeholder.enterName')"
                      :disabled="validAge >= 18"
                    />
                    <!--@change="() => saveDataTemporarily_temp()"-->
                    <small class="text-muted"> {{ $t('generic.onlyMinors') }}</small> <br>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="3"
                lg="3"
              >
                <b-form-group>
                  <label>{{ $t('generic.countryOfBirth') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="countryBirth"
                  >
                    <v-select
                      id="club-country-id"
                      v-model="member.country_birth_hash"
                      label="name"
                      :options="countries"
                      :reduce="country => country.id"
                      :placeholder="$t('generic.selectCountry')"
                      :state="errors.length > 0 ? false : null"
                    >
                      <div slot="no-options">
                        {{ $t('generic.noRecordsFound') }}
                      </div>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="3"
                lg="3"
              >
                <b-form-group>
                  <label>{{ $t('generic.stateOfBirth') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="stateBirth"
                  >
                    <v-select
                      id="club-country-id"
                      v-model="member.state_birth_hash"
                      label="name"
                      :options="memberBirthStates"
                      :reduce="city => city.hash"
                      :placeholder="$t('generic.selectState')"
                      :state="errors.length > 0 ? false : null"
                      :disabled="member.country_birth_hash == null"
                    >
                      <!--@input="() => saveDataTemporarily_temp()"-->
                      <div slot="no-options">
                        {{ $t('generic.noRecordsFound') }}
                      </div>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group>
                  <label>{{ $t('member.form.label.lastDegreeStudies') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="lastDegreeStudies"
                  >
                    <v-select
                      id="studies-id"
                      v-model="member.education_level_hash"
                      label="name"
                      :options="educationLevels"
                      :reduce="level => level.id"
                      :placeholder="$t('member.form.placeholder.selectStudies')"
                      :state="errors.length > 0 ? false : null"
                    >
                      <!--@input="() => saveDataTemporarily_temp()"-->
                      <div slot="no-options">
                        {{ $t('generic.noRecordsFound') }}
                      </div>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group>
                  <label>{{ $t('member.form.label.email') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required|email"
                    name="email"
                  >
                    <b-form-input
                      v-model="member.email"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('member.form.placeholder.enterEmail')"
                    />
                    <!--@change="() => saveDataTemporarily_temp()"-->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group>
                  <label>{{ $t('member.form.label.cellPhone') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="cellPhone"
                  >
                    <VuePhoneNumberInput
                      v-model="member.cell_phone"
                      valid-color="#98a1b3"
                      error-color="#cc296a"
                      color="#cc296a"
                      default-country-code="MX"
                      :translations="{
                        countrySelectorLabel: $t('generic.telephonePrefix'),
                        countrySelectorError: $t('generic.chooseCountry'),
                        phoneNumberLabel: $t('member.form.placeholder.enterCellPhone'),
                        example: $t('generic.example')
                      }"
                      @update="resultsCellPhone = $event"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="8"
                class="mt-18"
              >
                <span>{{ $t('member.form.label.currentAddress') }}</span>
                <hr>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group>
                  <label>{{ $t("accountSettings.address") }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required|max:255|addressRegex"
                    name="address"
                  >
                    <b-form-input
                      v-model="member.address.address"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('member.form.placeholder.enterAddressFull')"
                    />
                    <!--@change="() => saveDataTemporarily_temp()"-->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group>
                  <label for="postal-code">
                    {{ $t("accountSettings.postalCode") }}
                    <span class="text-danger">*</span>
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required|max:255|zipRegex"
                    name="postalCode"
                  >
                    <b-form-input
                      id="postal-code"
                      v-model="member.address.zip_code"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('accountSettings.postalCode')"
                    />
                    <!--@change="() => saveDataTemporarily_temp()"-->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group>
                  <label for="club-country-id">{{ $t('generic.country') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="country"
                  >
                    <v-select
                      id="club-country-id"
                      v-model="member.address.country_hash"
                      label="name"
                      :options="countries"
                      :reduce="country => country.id"
                      :placeholder="$t('generic.selectCountry')"
                      :state="errors.length > 0 ? false : null"
                    >
                      <div slot="no-options">
                        {{ $t('generic.noRecordsFound') }}
                      </div>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group>
                  <label for="club-state-id">{{ $t('generic.state') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="state"
                  >
                    <v-select
                      id="club-state-id"
                      v-model="member.address.state_hash"
                      label="name"
                      :options="memberAddressStates"
                      :reduce="city => city.hash"
                      :disabled="member.address.country_hash == null"
                      :loading="isLoadingAddressState"
                      :placeholder="$t('generic.selectState')"
                    >
                      <!--@input="() => saveDataTemporarily_temp()"-->
                      <div slot="no-options">
                        {{ $t('generic.noRecordsFound') }}
                      </div>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <!-- Condiciones contractuales -->
      <b-col
        v-if="currentActive == 3"
        cols="12"
      >
        <validation-observer
          ref="formContractualConditions"
        >
          <!-- :conditions="member.conditions" -->
          <contractual-conditions
            :member="member"
            :countries="countries"
            :states="states"
            :option="option"
            :color-primary-btn="colorPrimaryBtn"
            :section-coaching-staff="sectionCoachingStaff"
          />
        </validation-observer>
      </b-col>
      <b-col
        v-if="currentActive == 4"
        cols="12"
      >
        <validation-observer
          ref="formMemberDocument"
        >
          <b-row>
            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b-row>
                <b-col>
                  <!-- toggle button -->
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-toggle.collapse-Identificacion
                    class="w-100"
                    variant="outline-primary"
                  >
                    {{ $t('member.form.label.identification') }}
                  </b-button>
                  <b-collapse
                    id="collapse-Identificacion"
                    visible
                    class="mt-2"
                  >
                    <!-- Form identification -->
                    <b-row align-h="center">
                      <b-col>
                        <b-form-group>
                          <label>{{ $t('member.form.label.idNumber') }} <span class="text-danger">*</span></label>
                          <validation-provider
                            #default="{ errors }"
                            rules="required|max:254|alphaNumSpacesRegex"
                            name="idNumber"
                          >
                            <b-form-input
                              v-model="member.id_number"
                              type="text"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('member.form.placeholder.enterIdNumber')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row align-h="center">
                      <b-col>
                        <upload-file
                          :title="$t('member.form.label.identification')"
                          folder="members"
                          :disabled="member.club_hash == null"
                          :club-id="member.club_hash"
                          :file-title="member.file_identification ? member.file_identification.title : ''"
                          @uploaded-file="assignFileIdentification"
                        />
                      </b-col>
                    </b-row>
                    <b-row align-h="center">
                      <b-col>
                        <upload-file
                          :title="$t('member.form.label.birthCertificate')"
                          folder="members"
                          :disabled="member.club_hash == null"
                          :club-id="member.club_hash"
                          :file-title="member.file_birth_certificate ? member.file_birth_certificate.title : ''"
                          @uploaded-file="assignFileBirthCertificate"
                        />
                      </b-col>
                    </b-row>
                    <template v-if="option === sectionCoachingStaff">
                      <b-row align-h="center">
                        <b-col>
                          <upload-file
                            :title="$t('title-or-licence')"
                            folder="members"
                            :disabled="member.club_hash == null"
                            :club-id="member.club_hash"
                            :file-title="member.contractual_condition.file_title_or_licence ? member.contractual_condition.file_title_or_licence.title : ''"
                            @uploaded-file="assignFileTitleOrLicence"
                          />
                        </b-col>
                      </b-row>
                    </template>
                  </b-collapse>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b-row>
                <b-col class="mb-30">
                  <!-- toggle button -->
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-toggle.nationality
                    class="w-100"
                    variant="outline-primary"
                  >
                    {{ $t('member.form.label.nacionality') }}
                  </b-button>
                  <b-collapse
                    id="nationality"
                    visible
                    class="mt-2"
                  >
                    <div>
                      <div>
                        <b-form
                          ref="form"
                          @submit.prevent="addNationality"
                        >
                          <!-- Row Loop -->
                          <b-row
                            v-for="(item, index) in member.nationalities"
                            :id="item.id"
                            :key="item.id"
                            ref="row"
                          >
                            <b-col md="12">
                              <b-form-group>
                                <label>{{ $t('member.form.label.nacionality') }} <span class="text-danger">*</span></label>
                                <validation-provider
                                  #default="{ errors }"
                                  rules="required"
                                  name="nationality"
                                >
                                  <v-select
                                    id="club-country-id"
                                    v-model="member.nationalities[index].nationality_hash"
                                    label="name"
                                    :options="catalogNationalities"
                                    :reduce="country => country.id"
                                    :placeholder="$t('generic.selectCountry')"
                                  >
                                    <!--@change="saveDataTemporarily_temp"-->
                                    <div slot="no-options">
                                      {{ $t('generic.noRecordsFound') }}
                                    </div>
                                  </v-select>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="12">
                              <b-form-group>
                                <label>{{ $t('member.form.label.withPassport') }}</label><br>
                                <label for="passport"> {{ $t('generic.not') }} </label>
                                <b-form-checkbox
                                  v-model="member.nationalities[index].with_passport"
                                  :checked="true"
                                  :value="true"
                                  unchecked-value="false"
                                  name="check-button"
                                  class="ml-2"
                                  switch
                                  inline
                                />
                                <label
                                  class="pb-1"
                                  for="passport"
                                > {{ $t('generic.yes') }} </label>
                              </b-form-group>
                            </b-col>
                            <div
                              v-if="member.nationalities[index].with_passport === true"
                              class="col-md-12"
                            >
                              <b-col class="pl-0 mt-18">
                                <b-form-group>
                                  <label>{{ $t('member.form.label.passportNumber') }} <span class="text-danger">*</span></label>
                                  <validation-provider
                                    #default="{ errors }"
                                    rules="required|max:254|alphaNumSpacesRegex"
                                    name="passportNumber"
                                  >
                                    <b-form-input
                                      v-model="member.nationalities[index].passport_number"
                                      type="text"
                                      :state="errors.length > 0 ? false : null"
                                      :placeholder="$t('member.form.placeholder.enterVisaNumber')"
                                    />
                                    <!--@change="saveDataTemporarily_temp"-->
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col class="pl-0">
                                <upload-file
                                  :title="$t('member.form.label.passport')"
                                  folder="members"
                                  :disabled="member.club_hash == null"
                                  :club-id="member.club_hash"
                                  :file-title="member.nationalities[index].file_passport ? member.nationalities[index].file_passport.title : ''"
                                  @uploaded-file="assignFilePassport($event, index)"
                                />
                              </b-col>
                              <b-col class="pl-0">
                                <b-form-group>
                                  <label>{{ $t('member.form.label.expiration') }} <span class="text-danger">*</span></label>
                                  <validation-provider
                                    #default="{ errors }"
                                    rules="required"
                                    name="expiration"
                                  >
                                    <b-form-datepicker
                                      v-model="member.nationalities[index].passport_expiration"
                                      :locale="$i18n.locale"
                                      :min="new Date()"
                                      class="form-control"
                                      :placeholder="$t('member.form.placeholder.selectDate')"
                                      :state="errors.length > 0 ? false : null"
                                    />
                                    <!--:date-info-fn="() => saveDataTemporarily_temp()"-->
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-form-checkbox
                                v-model="member.nationalities[index].is_main"
                                class="custom-control-primary"
                                :value="true"
                                :unchecked-value="'false'"
                                @change="onChangeIsMain(index)"
                              >
                                <!-- :checked="modules.status" -->
                                {{ $t('generic.selectAsMain') }}
                              </b-form-checkbox>
                            </div>
                            <!-- Remove Button -->
                            <b-col
                              lg="12"
                              md="12"
                              class="mb-50"
                            >
                              <div class="float-right">
                                <b-button
                                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                  style="margin-left: 17px;"
                                  variant="secondary"
                                  pill
                                  @click="removeNationality(index)"
                                >
                                  {{ $t('buttons.putOff') }}
                                </b-button>
                              </div>
                            </b-col>
                            <b-col>
                              <hr>
                            </b-col>
                          </b-row>
                        </b-form>
                      </div>
                      <div>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          pill
                          :style="colorPrimaryBtn"
                          @click="addNationality"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                          />
                          <span>{{ $t('buttons.addNationality') }}</span>
                        </b-button>
                      </div>
                    </div>
                  </b-collapse>
                </b-col>
                <b-col
                  sm="12"
                  md="12"
                  lg="12"
                  class="mb-30"
                >
                  <!-- toggle button -->
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-toggle.collapse-visa
                    class="w-100"
                    variant="outline-primary"
                  >
                    {{ $t('member.form.label.visa') }}
                  </b-button>
                  <b-collapse
                    id="collapse-visa"
                    visible
                    class="mt-2"
                  >
                    <div>
                      <div>
                        <b-form
                          ref="form"
                          @submit.prevent="addVisa"
                        >
                          <!-- Row Loop -->
                          <b-row
                            v-for="(item, index) in member.visas"
                            :id="item.id"
                            :key="item.id"
                            ref="row"
                          >
                            <b-col md="12">
                              <!-- Form grupo -->
                              <b-form-group>
                                <label>{{ $t('member.form.label.issuedBy') }} <span class="text-danger">*</span></label>
                                <validation-provider
                                  #default="{ errors }"
                                  rules="required"
                                  name="country"
                                >
                                  <v-select
                                    id="club-country-id"
                                    v-model="member.visas[index].expedition_country_hash"
                                    label="name"
                                    :options="countries"
                                    :reduce="country => country.id"
                                    :placeholder="$t('generic.selectCountry')"
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <!--@change="saveDataTemporarily_temp"-->
                                    <div slot="no-options">
                                      {{ $t('generic.noRecordsFound') }}
                                    </div>
                                  </v-select>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>

                            </b-col>
                            <b-col
                              md="12"
                            >
                              <b-form-group>
                                <label>{{ $t('member.form.label.visaNumber') }} <span class="text-danger">*</span></label>
                                <validation-provider
                                  #default="{ errors }"
                                  rules="required|max:254|alphaNumSpacesRegex"
                                  name="visaNumber"
                                >
                                  <b-form-input
                                    v-model="member.visas[index].visa_number"
                                    type="text"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="$t('member.form.placeholder.enterVisaNumber')"
                                  />
                                  <!--@change="saveDataTemporarily_temp"-->
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <!-- Type of visa -->
                            <b-col
                              md="12"
                            >
                              <b-form-group>
                                <label>{{ $t('member.form.label.typeOfVisa') }} <span class="text-danger">*</span></label>
                                <validation-provider
                                  #default="{ errors }"
                                  rules="required|max:254|alphaNumSpacesRegex"
                                  name="typeOfVisa"
                                >
                                  <b-form-input
                                    v-model="member.visas[index].type_of_visa"
                                    type="text"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="$t('member.form.placeholder.typeOfVisa')"
                                  />
                                  <!--@change="saveDataTemporarily_temp"-->
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <!-- End type of visa -->
                            <b-col md="12">
                              <upload-file
                                :title="$t('member.form.label.visa')"
                                folder="members"
                                :disabled="member.club_hash == null"
                                :club-id="member.club_hash"
                                :file-title="member.visas[index].file_visa ? member.visas[index].file_visa.title : ''"
                                @uploaded-file="assignFileVisas($event, index)"
                              />
                              <!-- <b-form-group>
                                <label>{{ $t('member.form.label.visa') }} <span class="text-danger">*</span></label>
                                <b-form-file
                                  ref="visaFile"
                                  v-model="member.visas[index].file_visa"
                                  :browse-text="$t('buttons.explore')"
                                  :accept="acceptTypeFile"
                                  :placeholder="$t('member.form.placeholder.uploadDocument')"
                                  :drop-placeholder="$t('member.form.placeholder.uploadDocument')"
                                  no-drop
                                  @input="fileValidation(member.visas[index].file_visa, 'path_file_visa_media_file_hash')"
                                />
                              </b-form-group> -->
                            </b-col>
                            <b-col md="12">
                              <b-form-group>
                                <label>{{ $t('member.form.label.expiration') }} <span class="text-danger">*</span></label>
                                <validation-provider
                                  #default="{ errors }"
                                  rules="required"
                                  name="expiration"
                                >
                                  <!-- <flat-pickr
                                    v-model="member.visas[index].visa_expiration"
                                    class="form-control"
                                    :placeholder="$t('member.form.placeholder.selectDate')"
                                    :config="configDateExpiration"
                                  /> -->
                                  <!--@change="saveDataTemporarily_temp"-->
                                  <b-form-datepicker
                                    v-model="member.visas[index].visa_expiration"
                                    :locale="$i18n.locale"
                                    :min="new Date()"
                                    class="form-control"
                                    :placeholder="$t('member.form.placeholder.selectDate')"
                                    :state="errors.length > 0 ? false : null"
                                  />
                                  <!--:date-info-fn="() => saveDataTemporarily_temp()"-->
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <!-- </b-row> -->
                            <!-- Remove Button -->
                            <b-col
                              lg="12"
                              md="12"
                              class="mb-50"
                            >
                              <div class="float-right">
                                <!-- button delete -->
                                <b-button
                                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                  style="margin-left: 17px;"
                                  variant="secondary"
                                  pill
                                  @click="removeVisa(index)"
                                >
                                  {{ $t('buttons.putOff') }}
                                </b-button>
                              </div>
                            </b-col>
                            <b-col cols="12">
                              <hr>
                            </b-col>
                          </b-row>
                        </b-form>
                      </div>
                      <div>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          pill
                          :style="colorPrimaryBtn"
                          @click="addVisa"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                          />
                          <span>{{ $t('buttons.addVisa') }}</span>
                        </b-button>
                      </div>
                    </div>
                  </b-collapse>
                </b-col>
                <!-- <b-col /> -->
              </b-row>
            </b-col>
          </b-row>
        </validation-observer>
      </b-col>
    </b-row>

    <!-- Buttons  -->
    <b-row>
      <b-col cols="12">
        <hr>
        <b-button
          v-if="currentActive == 1"
          id="next"
          variant="primary"
          pill
          class="ml-1 float-right"
          :style="colorPrimaryBtn"
          :disabled="!validateRequiredFieldsIntegrante"
          @click="getNextView"
        >
          {{ $t('buttons.next') }}
        </b-button>
        <b-button
          v-if="currentActive == 2"
          id="next"
          variant="primary"
          pill
          class="ml-1 float-right"
          :style="colorPrimaryBtn"
          :disabled="!validateRequiredFieldsGeneral"
          @click="getNextView"
        >
          {{ $t('buttons.next') }}
        </b-button>
        <b-button
          v-if="currentActive == 3"
          id="next"
          variant="primary"
          pill
          class="ml-1 float-right"
          :style="colorPrimaryBtn"
          @click="getNextView"
        >
          {{ $t('buttons.next') }}
        </b-button>
        <b-button
          v-if="currentActive == 4"
          id="next"
          variant="primary"
          pill
          class="ml-1 float-right"
          :style="colorPrimaryBtn"
          @click="getNextView"
        >
          {{ $t('buttons.finalize') }}
        </b-button>
        <b-button
          id="previous"
          variant="secondary"
          pill
          class="ml-1 btn-left-form"
          @click="getPreviousView"
        >
          {{ $t('buttons.previous') }}
        </b-button>
        <b-button
          id="save"
          variant="dark"
          pill
          class="ml-1 btn-clear-form"
          :disabled="!validateRequiredFieldsGeneral"
          @click="saveAhead"
        >
          {{ $t('buttons.save') }}
        </b-button>
        <b-button
          id="next"
          variant="outline-secondary"
          pill
          class="ml-1 btn-clear-form"
          @click="deleteDataTemporarily"
        >
          {{ $t('generic.cleanForm') }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { isObject, hasKeyObject } from '@core/utils/utils'
import { validatorFileSize, validatorFileType } from '@core/utils/validations/validators'
import { ref, onMounted } from '@vue/composition-api'
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import checkPermission from '@/auth/permissions'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import UploadFile from '@/views/member/components/UploadFile.vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import UploadImage from '../partials/UploadImage.vue'
import JerseyNumberModal from './JerseyNumberModal.vue'
import HeadTitleWizard from './components/HeadTitleWizard.vue'
import ContractualConditions from './contractual-conditions/ContractualConditions.vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    UploadImage,
    VuePhoneNumberInput,
    JerseyNumberModal,
    ContractualConditions,
    UploadFile,
    HeadTitleWizard,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      disableUpdating: false,
      applyStoreIncomplete: false,
      incompleteData: null,
      incompleteRecordId: null,
      isLoadingCatalog: false,
      isLoadingStateOfBirth: false,
      isLoadingAddressState: false,
      isValidPersonal: false,
      isValidGeneral: false,
      isValidConditions: false,
      isValidDocument: false,
      errorsFormGeneral: [],
      errorsFormPersonal: [],
      errorsFormConditions: [],
      errorsFormDocument: [],
      validAge: 20,
      filterJersey: {
        teamId: null,
        page: 1,
        perPage: 50,
      },
      selected: true,
      required,
      email,
      applyOther: false,
      resultsCellPhone: {
        formattedNumber: null,
      },
      idCountry: null,
      idState: null,
      option: null,
      sectionPlayer: 'PLAYER',
      sectionCoachingStaff: 'COACHING_STAFF',

      nationalities: {
        id: null,
        nationality_hash: null,
        with_passport: true,
        file_passport: {},
        path_file_passport_media_file_hash: null,
        passport_number: null,
        passport_expiration: null,
        is_main: null,
        member_hash: null,
      },
      visas: {
        id: null,
        expedition_country_hash: null,
        file_visa: {},
        path_file_visa_media_file_hash: null,
        visa_number: null,
        type_of_visa: null,
        visa_expiration: null,
        member_hash: null,
      },
      tempQuery: {
        filtersObj: {},
        paramsObj: {
          // page: 1,
          // perPage: 15,
          included: 'team,club,category,memberType,playerStatus',
        },
      },
      gender_hash: '',
      genderSelect: null,
      // Lists catalogs
      memberAddressStates: [],
      memberBirthStates: [],
      clubStates: [],
      memberPlayerStatusKey: null,
      path_file_avatar: null,
      // Const
      acceptTypeFile: 'image/jpeg, image/png, image/jpg, application/pdf',
    }
  },

  setup() {
    const progress = ref(null)
    const previous = ref(null)
    const next = ref(null)
    const circles = ref(null)
    const currentActive = ref(null)

    onMounted(() => {
      progress.value = document.getElementById('i-progress')
      previous.value = document.getElementById('previous')
      next.value = document.getElementById('next')
      circles.value = document.querySelectorAll('.i-circle-progress')
      currentActive.value = 1
      // currentActive.value = 4

      if (circles.value.length === 1) {
        progress.value.style.width = '100%'
      } else if (circles.value.length === 2) {
        progress.value.style.width = '50%'
      } else if (circles.value.length >= 3) {
        // eslint-disable-next-line no-mixed-operators
        progress.value.style.width = `${(1) / (circles.value.length) * 100}%`
      }
    })

    const update = () => {
      circles.value.forEach((circle, idx) => {
        if (idx < currentActive.value) {
          circle.classList.add('active')
        } else {
          circle.classList.remove('active')
        }
      })

      const actives = ref(document.querySelectorAll('.active'))

      if (circles.value.length === 1) {
        progress.value.style.width = '100%'
      } else if (circles.value.length === 2) {
        // eslint-disable-next-line no-mixed-operators
        progress.value.style.width = `${(actives.value.length - 1) / 2 * 100}%`
      } else if (circles.value.length >= 3) {
        // eslint-disable-next-line no-mixed-operators
        progress.value.style.width = `${(actives.value.length - 1) / (circles.value.length) * 100}%`
      }

      if (currentActive.value === 1) {
        previous.value.disabled = false
        next.value.disabled = false
      } else if (currentActive.value === circles.value.length) {
        next.value.disabled = false
        previous.value.disabled = false
      } else {
        previous.value.disabled = false
        next.value.disabled = false
      }
    }

    return {
      currentActive,
      getPreviousView() {
        if (currentActive.value === 1 && previous) {
          this.$router.push({ name: 'member' })
        }
        currentActive.value -= 1

        if (currentActive.value < 1) {
          currentActive.value = 1
        }
        update()
      },

      async getNextView() {
        this.disableUpdating = true

        const assignCurrent = ref(false)

        if (currentActive.value === 1) {
          this.isValidGeneral = await this.$refs.formMemberGeneral.validate()
          this.errorsFormGeneral = await this.$refs.formMemberGeneral.errors
          assignCurrent.value = true
        } else if (currentActive.value === 2) {
          await this.assignState()
          this.isValidPersonal = await this.$refs.formMemberPersonal.validate()
          this.errorsFormPersonal = await this.$refs.formMemberPersonal.errors
          this.handleSaveMember()
          assignCurrent.value = true
        } else if (currentActive.value === 3) {
          this.isValidConditions = await this.$refs.formContractualConditions.validate()
          this.errorsFormConditions = await this.$refs.formContractualConditions.errors
          assignCurrent.value = true
          this.handleSaveMember()
        } else if (currentActive.value === 4) {
          this.isValidDocument = await this.$refs.formMemberDocument.validate()
          this.errorsFormDocument = await this.$refs.formMemberDocument.validate()
          if (this.isValidPersonal && this.isValidGeneral && this.isValidConditions && this.isValidDocument) {
            this.member.is_complete_data = true
            assignCurrent.value = true
            await this.onLastUpdateMember()
            this.goRouteMember()
            this.deleteDataTemporarily()
          }
        }

        if (assignCurrent.value) {
          currentActive.value += 1

          if (currentActive.value > circles.value.length) {
            currentActive.value = circles.value.length
          }

          update()
        } else {
          const arrayGeneral = Object.values(this.errorsFormGeneral)
          const arrayPerson = Object.values(this.errorsFormPersonal)
          const arrayConditions = Object.values(this.errorsFormConditions)
          const arrayDocument = Object.values(this.errorsFormDocument)
          const arrayErrors = arrayGeneral.concat(arrayPerson, arrayConditions, arrayDocument)
          let text = '<ul>'
          // eslint-disable-next-line no-inner-declarations
          function myFunction(value) {
            if (value.length > 0) {
              text += `<li style="list-style:none; font-size:13px;">${value}</li>`
            }
          }
          arrayErrors.forEach(myFunction)
          text += '</ul>'
          this.$swal({
            title: '¡Error!',
            icon: 'error',
            html:
              `${text}`,
            showConfirmButton: true,
            confirmButtonText: `${this.$t('generic.accept')}`,
            confirmButtonColor: this.colorRGB,
            customClass: {
              content: 'text-left',
            },
          })
        }

        setTimeout(() => {
          this.disableUpdating = false
        }, 8000)
      },
    }
  },

  computed: {
    ...mapGetters({
      role: 'role',
      member: 'memberStore/member',
      genderHash: 'memberStore/selectGenderHash',
      // Catalogs
      countries: 'countries',
      states: 'states',
      clubs: 'clubList',
      catalogNationalities: 'catalogs/nationalities',
      genders: 'catalogs/genders',
      categories: 'catalogs/categories',
      educationLevels: 'catalogs/educationLevels',
      staffJobPositions: 'catalogs/staffJobPositions',
      memberTypeList: 'memberTypeList',
      teams: 'teamStore/teamList',
      jerseyNumbers: 'teamStore/jerseyNumbers',
      clubInfo: 'clubInfo',
      statusList: 'statusList',
      colorPrimaryBtn: 'colorPrimaryBtn',
      colorRGB: 'colorRGBX',
      playerStatuses: 'catalogs/playerStatuses',
      membersTypes: 'catalogs/membersTypes',
    }),
    validateRequiredFieldsIntegrante() {
      return this.member.club_hash && this.member.team_hash
    },
    validateRequiredFieldsGeneral() {
      return this.member.name && this.member.first_name && this.member.club_hash && this.member.team_hash
    },
  },

  watch: {
    '$i18n.locale': function i18nlocale() {
      this.fetchCountries()
      this.fetchPlayerStatuses()
      this.getStaffJobPositions()
    },
    applyOther(value) {
      (value) ? this.member.staff_job_position_hash = null : this.member.another_job = ''
    },
    member: {
      handler() {
        if (!this.disableUpdating) {
          setTimeout(() => {
            this.saveDataTemporarily()
          }, 2000)
        }
      },
      deep: true,
    },
    'member.address.country_hash': function memberAddressIdCountry(pCountry, oldCountry) {
      if (pCountry !== null) {
        this.getMemberAddressStates(pCountry)
        // this.saveDataTemporarily_temp()
      }
      if (oldCountry) {
        this.member.address.state_hash = null
      }
    },
    'member.gender_hash': function memberGenderId(id, oldGenderId) {
      if (id != null) {
        this.tempQuery.filtersObj.gender_hash = id
        this.getStaffJobPositions()
        // this.saveDataTemporarily_temp()
      }
      if (oldGenderId) {
        this.member.staff_job_position_hash = null
      }
    },
    'member.club.country_hash': function idCountry(newcCtry, oldCtry) {
      if (newcCtry !== null) {
        this.getClubStates(newcCtry)
        // this.saveDataTemporarily_temp()
      }
      if (oldCtry) {
        this.member.club.state_hash = null
        this.member.club_hash = null
      }
    },
    'member.club.state_hash': function idState(ctry, oldCtry) {
      if (ctry !== null) {
        this.tempQuery.filtersObj.state_hash = ctry
        this.getClubs()
        // this.saveDataTemporarily_temp()
      }
      if (oldCtry) {
        this.member.club.state_hash = null
        this.member.club_hash = null
      }
    },
    'member.club_hash': function memberClubId(clubId, oldClubId) {
      if (clubId != null) {
        this.tempQuery.filtersObj.club_hash = clubId
        this.tempQuery.filtersObj.status = 'true'
        this.getTeams()
        // this.saveDataTemporarily_temp()
      }
      if (oldClubId) {
        this.member.team_hash = null
      }
    },
    'member.country_birth_hash': function memberCountryBirthID(ctry, oldCtry) {
      if (ctry !== null) {
        this.getMemberBirthStates(ctry)
        // this.fetchStateByCountryId(ctry)
        // this.saveDataTemporarily_temp()
      }
      if (oldCtry) {
        this.member.state_birth_hash = null
      }
    },
    // Register temporarily
    'member.team_hash': function memberTeamId(teamId) {
      if (teamId) {
        this.filterJersey.team_hash = teamId
        this.fetchCategories(this.tempQuery)
        // this.jerseyNumbersByteamId(this.filterJersey)
        this.handleJerseyByTeamId(this.filterJersey)
        // this.saveDataTemporarily_temp()
      }
    },
    'member.birthday': function memberBirthday(value) {
      if (value) {
        this.validAge = this.getAge(value)
        if (this.validAge >= 18) {
          this.member.holder_name = null
        }
        // this.saveDataTemporarily_temp()
      } else {
        this.member.holder_name = null
      }
    },
    'member.player_status': function memberPlayerStatus(sta) {
      if (isObject(sta)) {
        this.member.player_status_hash = sta.id
        this.memberPlayerStatusKey = sta.key
      }
      this.member.contractual_condition.transfer.ceding_club = null
    },
  },

  beforeDestroy() {
    // if (!this.validateRequiredFieldsGeneral) {
    //   this.deleteDataTemporarily()
    // }
    this.clearForm()
  },

  mounted() {
    this.option = this.$route.query.op
    if (this.option == null) {
      this.$router.push({ name: 'member' })
    }
    this.loadValues()
    this.gender_hash = this.$route.query.g
    this.getCataloges()
    this.tempQuery.filtersObj.gender_hash = this.gender_hash
    this.fetchCategories(this.tempQuery)
    this.getStaffJobPositions()
    this.getClubs()
  },

  methods: {
    hasKeyObject,
    validatorFileSize,
    validatorFileType,
    checkPermission,
    ...mapActions({
      fetchClubs: 'fetchClubs',
      // Catalogs
      fetchCountries: 'fetchCountries',
      fetchCategories: 'catalogs/fetchCategories',
      fetchEducationLevels: 'catalogs/fetchEducationLevels',
      fetchStaffJobPositions: 'catalogs/fetchStaffJobPositions',
      fetchGenders: 'catalogs/fetchGenders',
      fetchNationalities: 'catalogs/fetchNationalities',
      saveMember: 'memberStore/store',
      storeIncRecord: 'memberStore/storeIncompleteRecord',
      upIncRecord: 'memberStore/upIncompleteRecord',
      getIncRecord: 'memberStore/getIncompleteRecord',
      deleteIncRecord: 'memberStore/destroyIncompleteRecord',
      updateMember: 'memberStore/update',
      getMembersData: 'memberStore/getMembers',
      fetchTeamByIdClub: 'teamStore/fetchTeamByIdClub',
      fetchPlayerStatuses: 'catalogs/fetchPlayerStatuses',
      // Add
      fetchStateByCountryId: 'fetchStateByCountryId',
      fetchDataTeams: 'teamStore/fetchData',
      jerseyNumbersByteamId: 'teamStore/jerseyNumbersByteamId',
      mediaFileUpload: 'mediaFile/upload',
      mediaFileDestroy: 'mediaFile/destroy',
      fetchMembersTypes: 'catalogs/fetchMembersTypes',
    }),

    async assignState() {
      await this.getMemberAddressStates(this.member.address.country_hash)
      await this.getMemberBirthStates(this.member.country_birth_hash)
    },

    handleJerseyByTeamId(query) {
      this.filterJersey.team_hash = this.member.team_hash
      if (query) {
        this.filterJersey.perPage = query.perPage
        this.filterJersey.page = query.page
      }
      this.jerseyNumbersByteamId(this.filterJersey)
    },

    async saveDataTemporarily() {
      if (this.applyStoreIncomplete) {
        if (this.incompleteRecordId == null) {
          const memberObj = {
            member: this.member,
            type: this.$route.query.op,
            gender_id: this.$route.query.g,
          }

          await this.storeIncRecord(memberObj).then(({ data }) => {
            console.log(data)
            this.incompleteRecordId = data.data.hash
          })
        } else {
          const memberObj = { member: this.member, id: this.incompleteRecordId }
          await this.upIncRecord(memberObj).then(({ data }) => {
            console.log(data)
            this.incompleteRecordId = data.data.hash
          })
        }
      }
    },

    async deleteDataTemporarily() {
      if (this.incompleteRecordId != null) {
        await this.deleteIncRecord(this.incompleteRecordId).then(() => {
          this.incompleteRecordId = null
          this.clearForm()
          this.loadValues()
        })
      }
    },

    loadValues() {
      this.$store.commit('memberStore/setSelectGenderHash', this.$route.query.g)
      this.$store.commit('memberStore/setSelectType', this.$route.query.op)
      if (this.option === this.sectionCoachingStaff) {
        this.$store.commit('memberStore/SET_TYPE_CONTRACTUAL_CONDITION', 'FREE_CONTRACT')
        this.$store.commit('memberStore/SET_HIDE_PLAYER_AGENT', 'NO')
      }
    },

    async getClubs() {
      await this.fetchClubs(this.tempQuery).then(response => {
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    async getStaffJobPositions() {
      await this.fetchStaffJobPositions(this.tempQuery).then(() => {
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    async getTeams() {
      await this.fetchDataTeams(this.tempQuery).then(() => {
      }).catch(error => {
        this.responseCatch(error)
      }).finally(() => {
        this.isLoading = false
      })
    },

    async getMemberAddressStates(pCountry) {
      await this.fetchStateByCountryId(pCountry).then(response => {
        this.memberAddressStates = response.data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    async getMemberBirthStates(pCountry) {
      await this.fetchStateByCountryId(pCountry).then(response => {
        this.memberBirthStates = response.data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    async getClubStates(pCountry) {
      await this.fetchStateByCountryId(pCountry).then(response => {
        this.clubStates = response.data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    async getCataloges() {
      this.incompleteData = null
      this.isLoadingCatalog = true

      const objectAux = {
        type: this.$route.query.op,
        gender_id: this.$route.query.g,
        model: 'members',
      }

      this.getIncRecord(objectAux).then(({ data }) => {
        if (data.data.length == 0) {
          this.incompleteData = null
        } else {
          this.incompleteData = data.data
          this.incompleteRecordId = data.data.hash
        }
      })

      await this.fetchCountries()
      await this.fetchPlayerStatuses()
      await this.fetchMembersTypes().then(response => {
        const { data } = response.data
        let resultGender = null
        resultGender = data.find(item => item.gender_hash === Number(this.gender_hash))
        if (resultGender != null && resultGender !== undefined) {
          this.genderSelect = resultGender.labelables[0].label
        }
      })
      await this.fetchEducationLevels()
      await this.fetchNationalities()
      this.$nextTick(async () => {
        if (this.incompleteData != null) {
          const title = this.$t('attention-member')
          const text = this.$t('infromation-previous-record')

          await this.confirmationQuestion(title, text).then(async result => {
            setTimeout(() => { this.applyStoreIncomplete = true }, 4000)

            if (result.isConfirmed) {
              await this.$store.commit('memberStore/setMember', this.incompleteData.content)
            } else if (result.isDenied) {
              await this.deleteDataTemporarily()
            } else if (result.isDismissed) {
              this.clearForm()
              await this.goRouteMember()
            }
          }).catch(error => {
            this.responseCatch(error)
          })
        } else {
          setTimeout(() => { this.applyStoreIncomplete = true }, 4000)
        }

        if (!this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
          this.member.club_hash = this.clubInfo.hash
        }

        this.gender_hash = this.$route.query.g
      })

      await this.fetchGenders(this.tempQuery).then(() => {
        //
      }).catch(error => {
        this.responseCatch(error)
      })

      this.isLoadingCatalog = false
    },

    async saveAhead() {
      await this.handleSaveMember()
      await this.responseSuccessCreate(this.$t('The-member-has-been-saved-correctly'))
    },

    async handleSaveMember() {
      if (this.applyOther) {
        this.member.staff_job_position_hash = null
        this.member.job_position = null
      } else {
        this.member.another_job = null
      }
      if (!this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
        this.member.club_hash = this.clubInfo.hash
      }

      // TODO: test save member
      // membersTypes
      this.gender_hash = this.$route.query.g
      const memberType = this.membersTypes.find(e => e.key === this.option && e.gender_hash === Number(this.gender_hash))
      this.member.member_type_hash = memberType.id
      if (memberType.key !== this.sectionCoachingStaff) {
        this.member.gender_hash = this.gender_hash
      }
      // this.member.gender_hash = memberType.gender_hash
      this.tempQuery.filtersObj.member_type_key = memberType.key
      if (this.resultsCellPhone.formattedNumber) {
        this.member.cell_phone = this.resultsCellPhone.formattedNumber
      }
      // this.$store.commit('memberStore/setSelectType', this.option)
      if (this.member.hash != null) {
        await this.onUpdateMember()
      } else {
        await this.onSaveMember()
      }
    },

    async onSaveMember() {
      await this.saveMember(this.member).then(response => {
        const { data } = response.data
        this.member.hash = data.hash
        this.member.address_hash = data.address_hash
        Object.assign(this.member.contractual_condition, data.contractual_condition)
        this.member.contractual_condition_hash = data.contractual_condition_hash
        // this.saveDataTemporarily_temp()
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    async onUpdateMember() {
      await this.updateMember(this.member).then(response => {
        const { data } = response.data
        this.member.hash = data.hash
        this.member.address_hash = data.address_hash
        this.member.nationalities = data.nationalities
        this.member.visas = data.visas
        Object.assign(this.member.contractual_condition, data.contractual_condition)
        this.member.contractual_condition_hash = data.contractual_condition_hash
        this.getMembers()
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    async onLastUpdateMember() {
      await this.updateMember(this.member)
        .then(async () => {
          await this.responseSuccessCreate(this.$t('The-member-has-been-saved-correctly'))
        })
    },

    async goRouteMember() {
      await this.$router.push({
        name: 'member',
      })
    },

    addNationality() {
      this.member.nationalities.push({
        id: Date.now(),
        hash: null,
        nationality_hash: this.nationalities.nationality_hash,
        with_passport: this.nationalities.with_passport,
        file_passport: {},
        path_file_passport_media_file_hash: this.nationalities.path_file_passport_media_file_hash,
        passport_number: this.nationalities.passport_number,
        passport_expiration: this.nationalities.passport_expiration,
        is_main: this.nationalities.is_main,
        member_hash: this.member.hash,
      })
    },

    removeNationality(index) {
      this.member.nationalities.splice(index, 1)
    },

    addVisa() {
      this.member.visas.push({
        id: Date.now(),
        hash: null,
        expedition_country_hash: this.visas.expedition_country_hash,
        file_visa: {},
        path_file_visa_media_file_hash: this.visas.path_file_visa_media_file_hash,
        type_of_visa: this.visas.type_of_visa,
        visa_number: this.visas.visa_number,
        visa_expiration: this.visas.visa_expiration,
        member_hash: this.member.hash,
      })
    },

    removeVisa(index) {
      this.member.visas.splice(index, 1)
    },

    getMembers() {
    //  this.getMembersData(this.tempQuery)
    },

    onChangeIsMain(index) {
      this.member.nationalities.forEach((item, key) => {
        if (key !== index) {
          item.is_main = 0
        }
      })
    },

    getAge(dateString) {
      const hoy = new Date()
      const fechaNacimiento = new Date(dateString)
      let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
      const diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
      if (
        diferenciaMeses < 0 || (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
      ) {
        edad -= 1
      }
      return edad
    },

    assignFileContract(responseFile) {
      const previousFileHash = this.member.path_file_contract_media_file_hash
      if (previousFileHash != null) {
        this.mediaFileDestroy(previousFileHash)
          .then(() => {
          }).catch(error => {
            this.responseCatch(error)
          })
      }
      this.$set(this.member, 'file_contract', responseFile)
      this.$set(this.member, 'path_file_contract_media_file_hash', responseFile.hash)
    },

    assignFileVisas(responseFile, index) {
      const previousFileHash = this.member.visas[index].path_file_visa_media_file_hash
      if (previousFileHash != null) {
        this.mediaFileDestroy(previousFileHash)
          .then(() => {
          }).catch(error => {
            this.responseCatch(error)
          })
      }
      this.$set(this.member.visas[index], 'file_visa', responseFile)
      this.$set(this.member.visas[index], 'path_file_visa_media_file_hash', responseFile.hash)
    },

    assignFilePassport(responseFile, index) {
      const previousFileHash = this.member.nationalities[index].path_file_passport_media_file_hash
      if (previousFileHash != null) {
        this.mediaFileDestroy(previousFileHash)
          .then(() => {
          }).catch(error => {
            this.responseCatch(error)
          })
      }
      this.$set(this.member.nationalities[index], 'file_passport', responseFile)
      this.$set(this.member.nationalities[index], 'path_file_passport_media_file_hash', responseFile.hash)
    },

    assignFileIdentification(responseFile) {
      const previousFileHash = this.member.path_file_identification_media_file_hash
      if (previousFileHash != null) {
        this.mediaFileDestroy(previousFileHash)
          .then(() => {
          }).catch(error => {
            this.responseCatch(error)
          })
      }
      this.$set(this.member, 'file_identification', responseFile)
      this.$set(this.member, 'path_file_identification_media_file_hash', responseFile.hash)
    },

    assignFileBirthCertificate(responseFile) {
      const previousFileHash = this.member.path_file_birth_certificate_media_file_hash
      if (previousFileHash != null) {
        this.mediaFileDestroy(previousFileHash)
          .then(() => {
          }).catch(error => {
            this.responseCatch(error)
          })
      }
      this.$set(this.member, 'file_birth_certificate', responseFile)
      this.$set(this.member, 'path_file_birth_certificate_media_file_hash', responseFile.hash)
    },

    assignFileTitleOrLicence(responseFile) {
      const previousFileHash = this.member.contractual_condition.path_file_title_or_licence_media_file_hash
      if (previousFileHash != null) {
        this.mediaFileDestroy(previousFileHash)
          .then(() => {
          }).catch(error => {
            this.responseCatch(error)
          })
      }
      this.$set(this.member.contractual_condition, 'file_title_or_licence', responseFile)
      this.$set(this.member.contractual_condition, 'path_file_title_or_licence_media_file_hash', responseFile.hash)
    },

    async fileValidation(fileData, typeField) {
      // const validSize = this.validatorFileSize(fileData)
      const validType = this.validatorFileType(fileData)

      // if (!validSize) {
      //   if (typeField === 'avatar_file') this.path_file_avatar = null
      //
      //   this.showError(`${this.$t('the-file-size-exceeds-the-allowed-size')}: 2MB`)
      //   return
      // }
      if (!validType) {
        if (typeField === 'avatar_file') this.path_file_avatar = null

        this.showError(`${this.$t('only-documents-in-PDF-and-image-format-are-allowed')}`)
        return
      }
      this.saveFileUpload(fileData, typeField)
    },

    async saveFileUpload(fileData, typeField) {
      const formData = new FormData()
      formData.append('file', fileData)
      formData.append('folder', 'members')
      formData.append('club_id', this.member.club_hash)
      await this.mediaFileUpload(formData).then(response => {
        const { data } = response.data
        if (typeField === 'avatar_file') {
          const previousFileHash = this.member.avatar_file_media_file_hash
          if (previousFileHash != null) {
            this.mediaFileDestroy(previousFileHash)
              .then(() => {
              }).catch(error => {
                this.responseCatch(error)
              })
          }
          this.$set(this.member, 'avatar_file', data)
          this.$set(this.member, 'avatar_file_media_file_hash', data.hash)
        }
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    clearForm() {
      this.$store.commit('memberStore/resetState')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
// @import '@core/scss/vue/libs/vue-flatpicker.scss';
.repeater-form {
  overflow: visible;
  transition: .35s height;
}
.mb-30 {
  margin-bottom: 30px;
}
.btn-right {
  float: right !important;
}
.mt-18 {
  margin-top: 18px;
}
.btn-clear-form {
  float: right;

  @media (max-width: 412px) {
    margin-top: 15px;
    margin-left: 50px;
    width: 100%;
  }
}
.btn-left-form {
  float: right;
  @media (max-width: 412px) {
    float: left;
  }
}
</style>

<style lang="scss" scoped>
.i-progress-container {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-bottom: 30px;
  max-width: 100%;
  width: 550px;
  float: right;
}
.i-progress-container::before {
  content: '';
  background-color: #98a1b3;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 2px;
  width: 100%;
  z-index: 2;
}
button:disabled {
  cursor: not-allowed;
  opacity: 0.2;
  pointer-events: all !important;
}
</style>
